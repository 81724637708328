import React from "react";
import theme from "theme";
import { Theme, Span, Text, Link, Box, Section, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Главная | Теннисный центр Daulet
			</title>
			<meta name={"description"} content={"Каждый матч - шедевр!"} />
			<meta property={"og:title"} content={"Главная | Теннисный центр Daulet"} />
			<meta property={"og:description"} content={"Каждый матч - шедевр!"} />
			<link rel={"shortcut icon"} href={"https://solarvoxs.com/img/94119.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://solarvoxs.com/img/94119.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://solarvoxs.com/img/94119.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://solarvoxs.com/img/94119.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://solarvoxs.com/img/94119.png"} />
			<meta name={"msapplication-TileImage"} content={"https://solarvoxs.com/img/1.jpg"} />
			
		</Helmet>
		<Components.Header />
		<Section min-height="100vh" padding="0 0 0 0">
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				max-width="none"
				width="100%"
				sm-padding="72px 0px 0px 0px"
			/>
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 50px 0px"
				margin="40px 5% 40px 5%"
				lg-padding="24px 60px 24px 60px"
				sm-padding="0px 20px 0px 20px"
				padding="24px 0px 24px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					<Span color="--green">
					Daulet 
					</Span>
					Tennis Centre
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--lead" lg-text-align="center">
				Откройте для себя радость игры на настоящих глиняных кортах в теннисном центре Daulet. В нашем центре созданы все условия для игры в теннис высочайшего уровня, которые подойдут как энтузиастам, так и соревнующимся игрокам. Наслаждайтесь игрой в окружении чистого очарования глины - покрытия, которое предпочитали многие легенды тенниса.
				</Text>
				<Box
					display="flex"
					sm-flex-direction="column"
					sm-width="100%"
					sm-text-align="center"
					justify-content="flex-start"
					align-items="center"
				>
					<Link
						href="/contacts"
						padding="12px 54px 12px 54px"
						color="--light"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						background="--color-green"
						hover-background="#1e6856"
						hover-transition="background-color 0.2s ease-in-out 0s"
					>
						Связаться с нами
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				background="#FFFFFF url(https://solarvoxs.com/img/1.jpg) center center/cover no-repeat scroll padding-box"
				lg-min-height="400px"
			/>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="50%"
				lg-width="100%"
				lg-display="flex"
				lg-justify-content="center"
				padding="0px 16px 0px 0px"
				lg-padding="0px 0px 0px 0px"
			>
				<Box
					display="grid"
					grid-template-rows="repeat(9, 60px)"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="16px"
					max-width="480px"
					lg-margin="0px 0px 16px 0px"
					sm-grid-template-rows="repeat(9, 10vw)"
				>
					<Image
						src="https://solarvoxs.com/img/2.jpg"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-row="1 / span 5"
						grid-column="1 / span 1"
						height="100%"
					/>
					<Image
						src="https://solarvoxs.com/img/3.jpg"
						border-radius="24px"
						object-fit="cover"
						grid-column="2 / span 1"
						grid-row="2 / span 3"
						width="100%"
						height="100%"
					/>
					<Image
						src="https://solarvoxs.com/img/4.jpg"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-column="1 / span 1"
						grid-row="6 / span 3"
						height="100%"
					/>
					<Image
						src="https://solarvoxs.com/img/5.jpg"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-column="2 / span 1"
						grid-row="5 / span 5"
						height="100%"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="20px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				lg-width="100%"
				lg-padding="24px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Почему стоит выбрать Daulet?
				</Text>
				<Text margin="0px 0px 0px 0px" color="--greyD3" font="--lead" lg-text-align="center">
				Оптимальные условия для игры: Наши глиняные корты уменьшают количество сильных ударов, облегчая нагрузку на суставы и позволяя играть дольше без дискомфорта, обычно возникающего на более твердых покрытиях.
					<br />
					<br />
					Подходят для всех уровней мастерства: Будь вы новичок или профессионал, наши корты созданы для того, чтобы создать идеальные условия для совершенствования вашей игры.
					<br />
					<br />
					Ориентация на сообщество: В Daulet мы создаем сообщество игроков, которые разделяют страсть к теннису, предоставляя отличное место для встреч и соревнований с другими любителями тенниса.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://solarvoxs.com/img/6.jpg"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						min-height="100%"
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://solarvoxs.com/img/7.jpg"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Daulet - это не просто игра в теннис, это возможность почувствовать игру в ее чистом виде на ухоженных глиняных кортах. Здесь каждая подача и залп обретают свою собственную жизнь, позволяя вам играть дольше, с большей стратегией и меньшей нагрузкой на тело. Наш центр сосредоточен на совершенствовании ваших теннисных навыков в гостеприимной и профессиональной атмосфере.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
			Готовы править игрой?
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
			Присоединяйтесь к нам в Daulet, где ваша страсть к теннису встретится с нашими исключительными кортами!
			</Text>
			<Button
				font="--lead"
				margin="20px"
				href="/contacts"
				type="link"
				text-decoration-line="initial"
				background="--color-green"
			>
				Контакты
			</Button>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});